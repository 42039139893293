import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import Landing from "../components/page/landing/Landing";
import Terms from "../components/page/terms/Terms";
import Gallery from "../components/page/gallery/Gallery";
import Contact from "../components/page/contact/Contact";
import Care from "../components/page/care/Care";
import Collections from "../components/page/collections/Collections";
import Products from "../components/page/products/Products";
import About from "../components/page/about/About";
import "rsuite/dist/rsuite.css";
import './App.scss';

function App() {
  return (
    <HelmetProvider>
      <main className={"app"}>
        <BrowserRouter>
          <Routes>
            <Route path={"/"} element={<Landing />} />
            <Route path={"/terms"} element={<Terms />} />
            <Route path={"/care"} element={<Care />} />
            <Route path={"/gallery"} element={<Gallery />} />
            <Route path={"/contact"} element={<Contact />} />
            <Route path={"/collection"} element={<Collections />} />
            <Route path={"/collection/:product"} element={<Products />} />
            <Route path={"/about"} element={<About />} />
          </Routes>
        </BrowserRouter>
      </main>
    </HelmetProvider>
  );
}

export default App;
