import React, {useState} from "react";
import NavbarComponent, {MobileNavbar} from "../../navbar/NavbarComponent";
import Footer from "../../footer/Footer";
import useScrollToTop from "../../../hooks/useScrollToTop";
import {Helmet} from "react-helmet-async";
import EmailIcon from "../../../images/socials/email-icon.png";

const Contact = () => {
  const [openMobileDrawer, setOpenMobileDrawer] = useState<boolean>(false);

  useScrollToTop();

  return (
    <main className={"contact-page"}>
      <NavbarComponent />
      <MobileNavbar openMobileDrawer={openMobileDrawer} setOpenMobileDrawer={setOpenMobileDrawer} />
      <section className={"contact-content"}>
        <div className={"container"}>
          <div className={"intro"}>
            <h1>Get in touch:</h1>

            <p>Do you have any questions or some valuable feedback to share? I'm not just here to assist you; I'm here to make your experience exceptional. Don't hesitate to reach out to me using the contact information provided below:</p>

            <div className={"icon-holder"}>
              <a href={"mailTo:kampanjolacreations@gmail.com"} target={"_blank"} rel="noreferrer"><img className={"email"} src={EmailIcon} alt={"email"} rel="noreferrer" /></a>
            </div>

            <p>Feel free to drop me a line at <a href={"mailto:kampanjolacreations@gmail.com"}>kampanjolacreations@gmail.com</a>. I'm eagerly awaiting your messages!</p>
            <p>I genuinely value your input, and your thoughts are invaluable to me. I am always ready to go the extra mile to ensure your needs are met.</p>
          </div>

          <div className={"holder"}>
            <div className={"socials-container"}>
              <h2>Stay Connected:</h2>
              <p>In this fast-paced world, staying connected is more important than ever.</p>
              <p>Be sure to follow me on Facebook and Instagram! It's the best way to stay in the loop with my latest updates, exclusive offers, and tips on how to take care of your succulents. Let's grow together!</p>

              <div className={"icon-holder"}>
                <a href={"https://www.facebook.com/profile.php?id=100094477804629"} target={"_blank"} rel="noreferrer"><div className={"facebook"}></div></a>
                <a href={"https://instagram.com/kampanjolacreations"} target={"_blank"} rel="noreferrer"><div className={"instagram"}></div></a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

      <Helmet>
        <title>Contact Me - Il-Kampanjola</title>
        <meta property="og:title" content="Contact Me - Il-Kampanjola" />
        <meta name="twitter:title" content="Contact Me - Il-Kampanjola" />
        <meta property="og:title" content="Contact Me - Il-Kampanjola" />
        <meta name="description" content="All the needed contact information and social pages such as facebook and instagram for potential customers and clients that want to ask something or provide feedback." />
        <meta name="keywords" content="contact, contact me, facebook, instagram, email, collection, collections, products, Il-Kampanjola, kampanjola, succulent, succulents, arrangements, pots, plants, flowers, bouquet, gift, gifts, hand-made, hand-crafted, malta, unique, custom made" />
        <meta property="og:image" content="%PUBLIC_URL%/Kampanjola.jpg" />
        <link rel="canonical" href="https://www.kampanjolacreations.com/contact" />
      </Helmet>
    </main>
  );
}

export default Contact;
