import Logo from "./../../images/logos/Kampanjola-06.png";

const Loader = () => {
  return (
    <div className={"loader-container"}>
      <img className={"loader-logo"} src={Logo} alt={"Il-Kampanjola"} />
      <span className="loader"></span>
    </div>
  );
}

export default Loader;
