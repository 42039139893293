import React, {useState} from "react";
import NavbarComponent, {MobileNavbar} from "../../navbar/NavbarComponent";
import Footer from "../../footer/Footer";
import Banner from "../../banner/Banner";
import useScrollToTop from "../../../hooks/useScrollToTop";
import {Helmet} from "react-helmet-async";
import BannerImg from "../../../images/banner/banner2.svg";

const Care = () => {
  const [openMobileDrawer, setOpenMobileDrawer] = useState<boolean>(false);
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  useScrollToTop();

  return (
    <>
      <NavbarComponent />
      <MobileNavbar openMobileDrawer={openMobileDrawer} setOpenMobileDrawer={setOpenMobileDrawer} />
      <Banner
        setImageLoaded={setImageLoaded}
        imageLoaded={imageLoaded}
        img={BannerImg}
        alt={"Il-Kampanjola"}
        classname={"care-banner"}
      />

      <main className={"care-page"}>
        <section className={"care-content"}>
          <br />
          <h1><strong>Care Instructions:</strong></h1>
          <h4>Keep your succulents happy and healthy!</h4>

          <ul>
            <br />
            <li><strong>Lighting:</strong> Succulents thrive in bright, indirect sunlight. Place your arrangement near a window with filtered sunlight or in a well-lit area. When placing the arrangement outside, avoid exposing it to intense, direct sunlight for extended periods, as it may cause sunburn.</li>
            <br />
            <li><strong>Watering:</strong> Succulents have water-storing capabilities, so they prefer infrequent but deep watering. Allow the soil to dry out completely between waterings. The frequency of watering depends on the environment and the season. During winter, reduce watering as succulents enter dormancy. If you over water, the roots will rot and the plant will die. Remove saucers when watering and place them back only after the water has drained. Having saucers filled with water under the pot will lead to excessive moisture, causing the roots to decay.</li>
            <br />
            <li><strong>Temperature and Humidity:</strong> Succulents enjoy moderate temperatures and low humidity. Avoid exposing them to extreme cold or heat.</li>
            <br />
            <li><strong>Pruning:</strong> Remove dead or damaged leaves by gently plucking them from the base. This encourages healthy growth and prevents pest infestations.</li>
            <br />
            <li><strong>Pest Control:</strong> Inspect your succulents regularly for signs of pests such as mealybugs or aphids. If any pests are detected, treat them promptly.</li>
          </ul>
        </section>
        <Footer />
      </main>

      <Helmet>
        <title>Care Instructions from Il-Kampanjola</title>
        <meta property="og:title" content="Care Instructions from Il-Kampanjola" />
        <meta name="twitter:title" content="Care Instructions from Il-Kampanjola" />
        <meta property="og:title" content="Care Instructions from Il-Kampanjola" />
        <meta name="description" content="Here you will find the guide on how to care for your succulent arrangement for it to thrive and grow healthy." />
        <meta name="keywords" content="care, guide, healthy succulents, health, healthy, collection, collections, products, Il-Kampanjola, kampanjola, succulent, succulents, arrangements, pots, plants, flowers, bouquet, gift, gifts, hand-made, hand-crafted, malta, unique, custom made" />
        <meta property="og:image" content="%PUBLIC_URL%/Kampanjola.jpg" />
        <link rel="canonical" href="https://www.kampanjolacreations.com/care" />
      </Helmet>
    </>
  );
}

export default Care;
