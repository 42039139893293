import { Dispatch } from "react";
import ModalImage from "react-modal-image";

interface Fullscreen {
  image: any;
  setShowFullscreenImage: Dispatch<boolean>;
}

// const FullscreenImage = ({ image, setShowFullscreenImage }: Fullscreen) => {
//   return (
//     <div className="fullscreen" onClick={() => setShowFullscreenImage(false)}>
//       <div className="close" onClick={() => setShowFullscreenImage(false)}>x</div>
//       <div className="image-container">
//         <img className="image" src={image} alt="fullscreen" />
//       </div>
//     </div>
//   );
// };

const FullscreenImage = ({ image, setShowFullscreenImage }: Fullscreen) => {
  return (
    <div className="fullscreen" onClick={() => setShowFullscreenImage(false)}>
      <div className="close" onClick={() => setShowFullscreenImage(false)}>x</div>
      <ModalImage
        className={"img"}
        small={image}
        large={image}
        alt="fullscreen"
      />
    </div>
  );
};

export default FullscreenImage;
