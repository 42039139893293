import React from 'react';
import {Link} from "react-router-dom";

const SitemapLinks = () => {
  const sitemapLinks = [
    { name: 'Home', url: '/' },
    { name: 'About', url: '/about' },
    { name: 'Collection', url: '/collection' },
    { name: 'Gallery', url: '/gallery' },
    { name: 'Care', url: '/care' },
    { name: 'Terms', url: '/terms' },
    { name: 'Contact', url: '/contact' },
  ];
  const productLinks = [
    { name: 'Cups and Bowls', url: '/collection/cups-bowls' },
    { name: 'Ceramic Pots', url: '/collection/ceramic-pots' },
    { name: 'Wooden Planters', url: '/collection/wooden-planters' },
    { name: 'Terracotta Pots', url: '/collection/terracotta-pots' },
  ];

  return (
    <section className="sitemap-links">
      <div className={"logo-section"}>
        <h1 className={"sitemap-title"}>Il-Kampanjola</h1>
        <div className={"sitemap-logo"} />
      </div>

      <div className={"pages"}>
        <h2>Sitemap</h2>
        <ul>
          {sitemapLinks.map((link, index) => (
            <li key={index}>
              <Link to={link.url}>{link.name}</Link>
            </li>
          ))}
        </ul>
      </div>

      <div className={"products"}>
        <h2>Products</h2>
        <ul>
          {productLinks.map((link, index) => (
            <li key={index}>
              <Link to={link.url}>{link.name}</Link>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default SitemapLinks;
