import React, {useState} from "react";
import ImageGallery from "react-image-gallery";
import NavbarComponent, {MobileNavbar} from "../../navbar/NavbarComponent";
import Footer from "../../footer/Footer";
import Loader from "../../loader/Loader";
import useScrollToTop from "../../../hooks/useScrollToTop";
import {Helmet} from "react-helmet-async";

const Gallery = () => {
  const [openMobileDrawer, setOpenMobileDrawer] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const imageNames = Array.from({ length: 26 }, (_, index) => `${index + 1}.png`);

  useScrollToTop();

  React.useEffect(() => {
    setTimeout(() => loading && setLoading(false), 2000)
  }, [loading]);

  const loadImage = (imageName: any) =>
    import(`../../../images/gallery/${imageName}`).then((module) => module.default);

  const loadImages = () => Promise.all(imageNames.map(loadImage));

  const [images, setImages] = React.useState<Array<any>>([]); // Explicitly set the type for images

  React.useEffect(() => {
    loadImages().then((loadedImages) => {
      setImages(
        loadedImages.map((image, index) => ({
          original: image,
          thumbnail: image,
          originalAlt: `Image ${index + 1}`,
          thumbnailAlt: `Image ${index + 1}`,
          // originalHeight: (isTablet || !isMobile) ? '650px' : '400px',
          loading: true,
        }))
      );
    });
  }, []);

  return (
    <main className={"gallery-page"}>
      {
        loading
          ? <Loader />
          : null
      }
      <NavbarComponent />
      <MobileNavbar openMobileDrawer={openMobileDrawer} setOpenMobileDrawer={setOpenMobileDrawer} />
      <section className={"gallery-content"}>
        <ImageGallery items={images} slideInterval={5000} showPlayButton={true} autoPlay={true} lazyLoad={true} infinite={true} showBullets={true} useTranslate3D={true} />
      </section>
      <Footer />

      <Helmet>
        <title>Photo Gallery from Il-Kampanjola</title>
        <meta property="og:title" content="Photo Gallery from Il-Kampanjola" />
        <meta name="twitter:title" content="Photo Gallery from Il-Kampanjola" />
        <meta property="og:title" content="Photo Gallery from Il-Kampanjola" />
        <meta name="description" content="Photo gallery page for my beautiful succulent arrangement creations by hand." />
        <meta name="keywords" content="photos, gallery, collection, collections, products, Il-Kampanjola, kampanjola, succulent, succulents, arrangements, pots, plants, flowers, bouquet, gift, gifts, hand-made, hand-crafted, malta, unique, custom made" />
        <meta property="og:image" content="%PUBLIC_URL%/Kampanjola.jpg" />
        <link rel="canonical" href="https://www.kampanjolacreations.com/gallery" />
      </Helmet>
    </main>
  );
};

export default Gallery;
