import React, {useRef, useState} from "react";
import {useParams} from "react-router-dom";
import NavbarComponent, {MobileNavbar} from "../../navbar/NavbarComponent";
import useScrollToTop from "../../../hooks/useScrollToTop";
import CustomModal from "../../modal/Modal";
import Footer from "../../footer/Footer";
import FullscreenImage from "../../fullscreenImage/FullscreenImage";
import {Helmet} from "react-helmet-async";
import Images1 from "../../../images/cups-bowls/29.png";
import Images2 from "../../../images/cups-bowls/30.png";
import Images3 from "../../../images/cups-bowls/31.png";
import Images4 from "../../../images/cups-bowls/32.png";
import Images5 from "../../../images/cups-bowls/33.png";
import Images6 from "../../../images/cups-bowls/34.png";
import Images7 from "../../../images/cups-bowls/35.png";
import Images8 from "../../../images/cups-bowls/36.png";
import Images9 from "../../../images/cups-bowls/37.png";
import Images10 from "../../../images/cups-bowls/38.png";
import Images11 from "../../../images/cups-bowls/39.png";
import Images12 from "../../../images/cups-bowls/40.png";
import pots1 from "../../../images/pots/1.png";
import pots2 from "../../../images/pots/2.png";
import pots3 from "../../../images/pots/3.png";
import pots4 from "../../../images/pots/4.png";
import pots5 from "../../../images/pots/5.png";
import pots6 from "../../../images/pots/6.png";
import pots7 from "../../../images/pots/7.png";
import pots8 from "../../../images/pots/8.png";
import pots9 from "../../../images/pots/9.png";
import pots10 from "../../../images/pots/10.png";
import pots11 from "../../../images/pots/11.png";
import pots12 from "../../../images/pots/12.png";
import pots13 from "../../../images/pots/13.png";
import pots14 from "../../../images/pots/19.png";
import pots15 from "../../../images/pots/20.png";
import pots16 from "../../../images/pots/21.png";
import pots17 from "../../../images/pots/22.png";
import pots18 from "../../../images/pots/23.png";
import pots19 from "../../../images/pots/14.png";
import pots20 from "../../../images/pots/15.png";
import pots21 from "../../../images/pots/16.png";
import pots22 from "../../../images/pots/17.png";
import pots23 from "../../../images/pots/18.png";
import pots24 from "../../../images/pots/24.png";
import pots25 from "../../../images/pots/25.png";
import pots26 from "../../../images/pots/26.png";
import pots27 from "../../../images/pots/27.png";
import pots28 from "../../../images/pots/28.png";
import wood1 from "../../../images/wooden-planters/7.png";
import wood2 from "../../../images/wooden-planters/8.png";
import wood3 from "../../../images/wooden-planters/9.png";
import wood4 from "../../../images/wooden-planters/10.png";
import terra1 from "../../../images/terracotta-pots/14.png";
import terra2 from "../../../images/terracotta-pots/15.png";
import gift1 from "../../../images/gift-boxes/1.png";
import gift2 from "../../../images/gift-boxes/2.png";
import gift3 from "../../../images/gift-boxes/3.png";
import gift4 from "../../../images/gift-boxes/4.png";
import gift5 from "../../../images/gift-boxes/5.png";
import gift6 from "../../../images/gift-boxes/6.png";
import gift7 from "../../../images/gift-boxes/7.png";
import gift8 from "../../../images/gift-boxes/8.png";
import gift9 from "../../../images/gift-boxes/9.png";
import gift10 from "../../../images/gift-boxes/10.png";
import gift11 from "../../../images/gift-boxes/11.png";
import gift12 from "../../../images/gift-boxes/12.png";
import gift13 from "../../../images/gift-boxes/13.png";
import gift14 from "../../../images/gift-boxes/14.png";
import gift15 from "../../../images/gift-boxes/15.png";
import gift16 from "../../../images/gift-boxes/16.png";
import gift17 from "../../../images/gift-boxes/17.png";
import gift18 from "../../../images/gift-boxes/18.png";
import painting1 from "../../../images/paintings/19.png";
import painting2 from "../../../images/paintings/20.png";
import painting3 from "../../../images/paintings/21.png";
import painting4 from "../../../images/paintings/22.png";
import painting5 from "../../../images/paintings/23.png";
import painting6 from "../../../images/paintings/24.png";
import painting7 from "../../../images/paintings/25.png";
import painting8 from "../../../images/paintings/26.png";

export interface ProductsInterface {
  image: any;
  slider: any[];
  price?: string;
  intro: string;
  giftPack: string[];
  name: string;
  bagAddon: string;
  note: string;
}

const Products = () => {
  const {product} = useParams();
  const [openMobileDrawer, setOpenMobileDrawer] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalProduct, setModalProduct] = useState<ProductsInterface | undefined>(undefined);
  const [showFullscreenImage, setShowFullscreenImage] = useState<boolean>(false);
  const imageRef = useRef<any>(null);

  useScrollToTop();

  const productsToShow: () => (ProductsInterface[] | undefined | any) = () => {

    switch (product) {
      case "cups-bowls": return [
        {
          name: "Jingle Bells Cups",
          intro: "Make this festive season special with one of these stunning succulent gift cups, a thoughtful gift for everyone on your Christmas list.",
          price: "€19.50",
          bagAddon: "Add €1.90 for paper bag, bow, and decorative paper",
          image: Images1,
          slider: [Images1, Images2, Images3, Images4, Images5, Images6, Images7, Images8, Images9, Images10],
          giftPack: ["1x Echeveria & Anacampseros plants (Depends on colour of cup chosen)", "1x Ceramic cup (turquoise, black, grey or transparent)", "Decorative stones", "Overall size approx.10cm by 12cm"],
          note: "While we aim to include succulents matching the ones shown in the photo, please understand that availability is subject to stock levels. In the event that the exact succulent types is not available, we may substitute it with equally beautiful and complementary succulent varieties to ensure the quality and aesthetics of your gift."
        },
        // {
        //   name: "Mr.Grinch Glass Cup",
        //   intro: "Share the beauty of this charming cacti gift creation, a Christmas present that's sure to please everyone on your list.",
        //   price: "€16.50",
        //   bagAddon: "Add €1.90 for paper bag, bow, and decorative paper",
        //   image: Images11,
        //   slider: [Images11, Images12],
        //   giftPack: ["1x Cactus plant", "1x Glass transparent cup", "Decorative stones", "Overall size 10cm by 16cm"],
        //   note: "While we aim to include succulents matching the ones shown in the photo, please understand that availability is subject to stock levels. In the event that the exact succulent types is not available, we may substitute it with equally beautiful and complementary succulent varieties to ensure the quality and aesthetics of your gift."
        // },
      ];
      case "ceramic-pots": return [
        {
          name: "Holly Jolly Terracotta Pot",
          intro: "Celebrate the joy of the season by gifting an exquisite succulent pot creation, a Christmas present that suits everyone!",
          price: "€28.50",
          bagAddon: "Add €1.90 for paper bag, bow, and decorative paper",
          image: pots1,
          slider: [pots1, pots2],
          giftPack: ["1x Echeveria plant", "1x Terracotta pot", "Decorative moss", "Overall size approx. 16cm by 22cm"],
          note: "While we aim to include succulents matching the ones shown in the photo, please understand that availability is subject to stock levels. In the event that the exact succulent types is not available, we may substitute it with equally beautiful and complementary succulent varieties to ensure the quality and aesthetics of your gift."
        },
        {
          name: "Christmas Bells Ceramic Pot",
          intro: "Spread the holiday cheer with this exquisite succulent gift pot, a perfect Christmas treat for anyone you know!",
          price: "€24",
          bagAddon: "Add €1.90 for paper bag, bow, and decorative paper",
          image: pots3,
          slider: [pots3, pots4],
          giftPack: ["1x Echeveria plant", "1x Red ceramic pot", "Decorative filler and bow", "Overall size approx. 13cm by 16cm"],
          note: "While we aim to include succulents matching the ones shown in the photo, please understand that availability is subject to stock levels. In the event that the exact succulent types is not available, we may substitute it with equally beautiful and complementary succulent varieties to ensure the quality and aesthetics of your gift."
        },
        {
          name: "Christmas Bliss Plastic Pot",
          intro: "Spread the holiday cheer with this exquisite succulent gift pot, a perfect Christmas treat for anyone you know!",
          price: "€27",
          bagAddon: "Add €1.90 for paper bag, bow, and decorative paper",
          image: pots5,
          slider: [pots5, pots6, pots7, pots8],
          giftPack: ["1x Echeveria plant", "1x Red plastic pot round or square", "Decorative Moss", "Overall size approx. 14cm by 20cm"],
          note: "While we aim to include succulents matching the ones shown in the photo, please understand that availability is subject to stock levels. In the event that the exact succulent types is not available, we may substitute it with equally beautiful and complementary succulent varieties to ensure the quality and aesthetics of your gift."
        },
        {
          name: "White Christmas Ceramic Pot",
          intro: "This Christmas, bring a touch of nature indoors with this stunning succulent gift pot, an ideal gift for anyone on your Christmas list.",
          bagAddon: "Add €1.90 for paper bag, bow, and decorative paper",
          image: pots9,
          slider: [pots9, pots10, pots11, pots12, pots13],
          giftPack: ["1x Echeveria plant", "1x White ceramic pot", "Decorative stones", "Overall size 13cm by 16cm. Price: €24", "Overall size 16cm by 22cm. Price:€28.50"],
          note: "While we aim to include succulents matching the ones shown in the photo, please understand that availability is subject to stock levels. In the event that the exact succulent types is not available, we may substitute it with equally beautiful and complementary succulent varieties to ensure the quality and aesthetics of your gift."
        },
        {
          name: "Winter Wonderland Ceramic Pot",
          intro: "This Christmas, bring a touch of nature indoors with this stunning succulent gift pot, an ideal gift for anyone on your Christmas list.",
          bagAddon: "Add €1.90 for paper bag, bow, and decorative paper",
          image: pots14,
          slider: [pots14, pots15, pots16, pots17, pots18],
          giftPack: ["1x Echeveria plant", "1x Lilac ceramic pot", "Decorative stones", "Overall size 13cm by 16cm. Price: €24", "Overall size 16cm by 22cm. Price:€28.50"],
          note: "While we aim to include succulents matching the ones shown in the photo, please understand that availability is subject to stock levels. In the event that the exact succulent types is not available, we may substitute it with equally beautiful and complementary succulent varieties to ensure the quality and aesthetics of your gift."
        },
        {
          name: "Deck the Halls Ceramic Pot",
          intro: "This Christmas, bring a touch of nature indoors with this stunning succulent gift pot, an ideal gift for anyone on your Christmas list.",
          bagAddon: "Add €1.90 for paper bag, bow, and decorative paper",
          image: pots19,
          slider: [pots19, pots20, pots21, pots22, pots23],
          giftPack: ["1x Echeveria plant", "1x Rose Pink ceramic pot", "Decorative stones", "Overall size 13cm by 16cm. Price: €24", "Overall size 16cm by 22cm. Price:€28.50"],
          note: "While we aim to include succulents matching the ones shown in the photo, please understand that availability is subject to stock levels. In the event that the exact succulent types is not available, we may substitute it with equally beautiful and complementary succulent varieties to ensure the quality and aesthetics of your gift."
        },
        {
          name: "Silent night Ceramic Pot",
          intro: "This Christmas, bring a touch of nature indoors with this stunning succulent gift pot, an ideal gift for anyone on your Christmas list.",
          bagAddon: "Add €1.90 for paper bag, bow, and decorative paper",
          image: pots24,
          slider: [pots24, pots25, pots26],
          giftPack: ["1x Echeveria plant or Aloe Humilis plant (Depending on pot size chosen)", "1x Grey stone effect ceramic pot", "Decorative stones", "Overall size 13cm by 16cm. Price: €24", "Overall size 16cm by 22cm. Price:€28.50"],
          note: "While we aim to include succulents matching the ones shown in the photo, please understand that availability is subject to stock levels. In the event that the exact succulent types is not available, we may substitute it with equally beautiful and complementary succulent varieties to ensure the quality and aesthetics of your gift."
        },
        {
          name: "Blue Christmas Ceramic Pot",
          intro: "Make this holiday season memorable by gifting this exquisite succulent gift pot, a perfect Christmas surprise for all.",
          price: "€28.50",
          bagAddon: "Add €1.90 for paper bag, bow, and decorative paper",
          image: pots27,
          slider: [pots27, pots28],
          giftPack: ["1x Echeveria plant", "1x White ceramic bowl", "Decorative Stones", "Overall size 15cm by 12cm"],
          note: "While we aim to include succulents matching the ones shown in the photo, please understand that availability is subject to stock levels. In the event that the exact succulent types is not available, we may substitute it with equally beautiful and complementary succulent varieties to ensure the quality and aesthetics of your gift."
        }
      ];
      case "wooden-planters": return [wood1, wood2, wood3, wood4];
      case "terracotta-pots": return [terra1, terra2];
      case "gift-boxes": return [
        {
          name: "Christmas Gift Box - 1",
          intro: "This gorgeous gift box is the perfect Christmas gift for everyone on your list!",
          price: "€23.50",
          bagAddon: "",
          image: gift1,
          slider: [gift1, gift2, gift3, gift4],
          giftPack: ["1 x Echeveria (Random selection) succulent", "1x Antique Teacup", "1 x Christmas holiday card", "1x Mince pie", "Box with transparent window", "Overall size of gift box 120cm by 120cm by 120cm."],
          note: "While we aim to include succulents matching the ones shown in the photo, please understand that availability is subject to stock levels. In the event that the exact succulent types is not available, we may substitute it with equally beautiful and complementary succulent varieties to ensure the quality and aesthetics of your gift."
        },
        {
          name: "Christmas Gift Box -2",
          intro: "This gorgeous gift box is the perfect Christmas gift for everyone on your list!",
          price: "€23.50",
          bagAddon: "",
          image: gift13,
          slider: [gift13, gift14, gift15, gift16, gift17, gift18],
          giftPack: ["1 x Cactus plant (Randomly chosen)", "1x Glass Container", "1 x Christmas holiday card", "1x Mince pie", "Box with transparent window", "Overall size of gift box 120cm by 120cm by 120cm"],
          note: "While we aim to include succulents matching the ones shown in the photo, please understand that availability is subject to stock levels. In the event that the exact succulent types is not available, we may substitute it with equally beautiful and complementary succulent varieties to ensure the quality and aesthetics of your gift."
        },
        {
          name: "Christmas Gift Box - 3",
          intro: "This gorgeous gift box is the perfect Christmas gift for everyone on your list!",
          price: "€23.50",
          bagAddon: "",
          image: gift10,
          slider: [gift8, gift9, gift10, gift11, gift12],
          giftPack: ["1 x Echeveria (Random selection) succulent", "1x Glass Container", "1 x Christmas holiday card", "1x Mince pie", "1 x Christmas wreath", "Box with transparent window", "Overall size of gift box 120cm by 120cm by 120cm"],
          note: "While we aim to include succulents matching the ones shown in the photo, please understand that availability is subject to stock levels. In the event that the exact succulent types is not available, we may substitute it with equally beautiful and complementary succulent varieties to ensure the quality and aesthetics of your gift."
        },
        {
          name: "Christmas Gift Box - 4",
          intro: "This gorgeous gift box is the perfect Christmas gift for everyone on your list!",
          price: "€23.50",
          bagAddon: "",
          image: gift5,
          slider: [gift5, gift6, gift7],
          giftPack: ["1 x Aeonium succulent", "1x Glass Container", "1 x Christmas holiday card", "1x Mince pie", "Box with transparent window", "Overall size of gift box 120cm by 120cm by 120cm"],
          note: "While we aim to include succulents matching the ones shown in the photo, please understand that availability is subject to stock levels. In the event that the exact succulent types is not available, we may substitute it with equally beautiful and complementary succulent varieties to ensure the quality and aesthetics of your gift."
        },
      ];
      case "christmas-duo": return [
        {
          name: "Christmas Duo - Limited Edition",
          intro: "Capturing the essence of succulents on canvas, adorned with vibrant strokes, and paired with a lovely Aloe / Haworthia succulent in a glass container – this masterpiece is the Perfect Christmas Duo. Elevate the spirit of giving with the timeless blend of art and nature.",
          price: "€45 each",
          bagAddon: "",
          image: painting1,
          slider: [painting1, painting2, painting3, painting4, painting5, painting6, painting7, painting8],
          giftPack: ["1 x Canvas painting by local artist, size 24cm by 30cm (portrait)", "1x Aloe / Haworthia succulent in a glass container", "Paper bag, bow and wrapping for painting"],
          note: "While we aim to include succulents matching the ones shown in the photo, please understand that availability is subject to stock levels. In the event that the exact succulent types is not available, we may substitute it with equally beautiful and complementary succulent varieties to ensure the quality and aesthetics of your gift."
        },
      ];
      default: break;
    }
  }

  const titleToShow = () => {
    switch (product) {
      case "cups-bowls": return "Cups";
      case "ceramic-pots": return "Pots";
      case "wooden-planters": return "Wooden Planters";
      case "terracotta-pots": return "Terracotta Pots";
      case "gift-boxes": return "Gift Boxes";
      case "christmas-duo": return "Christmas Duo";
      default: break;
    }
  }

  return (
    <>
      {showFullscreenImage && <FullscreenImage setShowFullscreenImage={setShowFullscreenImage} image={imageRef.current}/>}
      <NavbarComponent />
      <MobileNavbar openMobileDrawer={openMobileDrawer} setOpenMobileDrawer={setOpenMobileDrawer} />

      <main className={"cup-collections-page"}>
        {
          <h1>{titleToShow()}</h1>
        }

        <section className={"collections"}>
          {
            product === "cups-bowls"
            || product === "ceramic-pots"
            || product === "gift-boxes"
            || product === "christmas-duo"
              ? productsToShow()?.map((prod: ProductsInterface)  => <div
                className={"cups-bowls"}
                key={prod.image}
                onClick={() => {
                  setModalProduct(prod);
                  setOpenModal(true);
              }}>
                <img className={"image"} src={prod.image} alt={"succulents-bowl"} />
                <p>{prod.name}</p>
              </div>)
              : productsToShow()?.map((prod: any): any => <div
                className={"cups-bowls"}
                key={prod}
                style={{height: 300}}
                onClick={() => {
                  imageRef.current = prod;
                  setShowFullscreenImage(true);
                }}>
                <img className={"image"} src={prod} alt={"succulents-bowl"} />
              </div>)
          }
        </section>

        <CustomModal open={openModal} handleClose={() => setOpenModal(false)} product={modalProduct} />
      </main>
      <Footer />

      <Helmet>
        <title>Product: {titleToShow()}</title>
        <meta property="og:title" content={`Product: ${titleToShow()}`} />
        <meta name="twitter:title" content={`Product: ${titleToShow()}`} />
        <meta property="og:title" content={`Product: ${titleToShow()}`} />
        <meta name="description" content={`Product: ${titleToShow()}`} />
        <meta name="keywords" content="product, for sale, shop, Il-Kampanjola, kampanjola, succulent, succulents, arrangements, pots, plants, flowers, bouquet, gift, gifts, hand-made, hand-crafted, malta, unique, custom made, fresh flowers, florist" />
        <link rel="canonical" href={`https://www.kampanjolacreations.com/collection/${product}`} />
      </Helmet>
    </>
  );
}

export default Products;
