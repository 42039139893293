import React, {useState} from "react";
import {Link} from "react-router-dom";
import NavbarComponent, {MobileNavbar} from "../../navbar/NavbarComponent";
import Footer from "../../footer/Footer";
import useScrollToTop from "../../../hooks/useScrollToTop";
import Banner from "../../banner/Banner";
import {Helmet} from "react-helmet-async";
import BannerImg from "../../../images/banner/banner4.svg";
import Me from "../../../images/misc/me.jpg";

const About = () => {
  const [openMobileDrawer, setOpenMobileDrawer] = useState<boolean>(false);
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  useScrollToTop();

  return (
    <>
      <NavbarComponent />
      <MobileNavbar openMobileDrawer={openMobileDrawer} setOpenMobileDrawer={setOpenMobileDrawer} />
      <Banner
        setImageLoaded={setImageLoaded}
        imageLoaded={imageLoaded}
        classname={"about-banner"}
        alt={"Il-Kampanjola"}
        img={BannerImg}
      />

      <main className={"about-page"}>
        <section className={"about-content"}>
          <h1 className={"about-title"}>About Me</h1>

          <h3 className={"about-subtitle"}>Welcome to Il-Kampanjola!</h3>

          <h5>Hi there, I'm Marylin, the beating heart behind Il-Kampanjola.</h5>

          <img className={"me"} src={Me} alt={"marylin"} />

          <p>I wanted to share with you the story behind Il-Kampanjola, as crafting succulent creations isn't just a way to make some extra money; it's my way of expressing my creativity and passion for succulents.  After a lot and a lot of pondering, since I am not very good with words, I decided to to give it a go. So here it goes!</p>

          <p>For as long as I can remember, I've been captivated by the charm of plants, but it wasn't until I discovered the enchanting realm of succulents that I truly fell in love. Succulents, with their vast varieties, colours, shapes, and forms, stole my heart. Not only are they breathtakingly beautiful, but their resilience and ability to thrive in diverse conditions resonated with me on a deeper level.</p>

          <p>Whenever possible, I start my day on the terrace, sipping tea and admiring my beloved succulents. Their care brings me immense joy and they remind me to keep strong and resilient to what ever the day will throw at me!  A perfect booster to start up my day!  It was one of these mornings when ‘the idea’ blossomed – why not share my passion and creations with others?</p>

          <p>With the unwavering support of my partner, daughter, my mother and father and plenty of hard work, Il-Kampanjola was born. I may not have a physical shop or a state of the art business like many others here in Malta, but I can assure you that everything you see is crafted with love and creativity right on my home terrace and at my fathers garden, which I now took over with all my succulents and pots!</p>

          <p>If you're curious about what I create, I invite you to explore <Link to={"/collection"}>my collection section</Link> and to follow me on my <a href={"https://www.facebook.com/profile.php?id=100094477804629"} target={"_blank"} rel="noreferrer">facebook page</a>. At Il-Kampanjola, you'll find a unique collection of handcrafted succulent arrangements and creations that make for special, budget-friendly gifts for him, her, family, and friends. These vibrant and resilient succulents are perfect for every occasion – birthdays, housewarmings, get-well-soon gestures, and heartfelt thank-yous. Whether you're searching for a one-of-a-kind present or want to add a touch of green to your own space, my succulent arrangements and creations offer a perfect solution.</p>

          <p>Thank you for being a part of this heartfelt journey.</p>

          <p>With green hugs and leafy love,</p>

          <p>Marylin</p>

        </section>
        <Footer />
      </main>

      <Helmet>
        <title>About Il-Kampanjola</title>
        <meta property="og:title" content="About Il-Kampanjola" />
        <meta name="twitter:title" content="About Il-Kampanjola" />
        <meta property="og:title" content="About Il-Kampanjola" />
        <meta name="description" content="About il-Kampanjola, how it came to be and where it originates from" />
        <meta name="keywords" content="about us, about me, Il-Kampanjola, kampanjola, succulent, succulents, arrangements, creations, expressing myself, resilient plants, resilient flowers, explore, shop, business, malta, craft, creativity, story, journey" />
        <meta property="og:image" content="%PUBLIC_URL%/Kampanjola.jpg" />
        <link rel="canonical" href="https://www.kampanjolacreations.com/about" />
      </Helmet>
    </>
  );
}

export default About;
