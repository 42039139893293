import { Modal, Button } from 'rsuite';
import {ProductsInterface} from "../page/products/Products";
import ImageGallery from "react-image-gallery";

interface CustomModalTypes {
  open: boolean;
  handleClose: () => void;
  product: ProductsInterface | undefined;
}

const CustomModal = ({open, handleClose, product}: CustomModalTypes) => {
  let images: any = [];

  product?.slider.map(slide => images.push({
    original: slide,
    thumbnail: slide,
    // originalHeight: "300px"
  }))

  return (
    <Modal backdrop={"static"} className={"main-modal"} size={"full"} open={open} onClose={handleClose}>
      <Modal.Body>
        <section className={"modal-product-section"}>
          <article>
            <div id={"slider"}>
              <ImageGallery items={images} slideInterval={5000} showPlayButton={true} autoPlay={true} lazyLoad={true} infinite={true} showBullets={false} useTranslate3D={true} />
            </div>
          </article>

          <article>
            <h1>{product?.name}</h1>
            <p>{product?.intro}</p>
            <p><strong>Includes:</strong></p>
            <ul>
              {product?.giftPack.map(gift => <li>{gift}</li>)}
            </ul>
            {product?.price? <p><strong>Price:</strong> {product?.price}</p> : null}
            <p>{product?.bagAddon}</p>
            <p className={"note"}>{product?.note}</p>
          </article>
        </section>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} appearance="primary" className={"button"} size={"lg"}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CustomModal;
