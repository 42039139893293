import React, {useState} from "react";
import NavbarComponent, {MobileNavbar} from "../../navbar/NavbarComponent";
import Footer from "../../footer/Footer";
import Banner from "../../banner/Banner";
import {Helmet} from "react-helmet-async";
import BannerImg from "../../../images/banner/banner.svg";

const Terms = () => {
  const [openMobileDrawer, setOpenMobileDrawer] = useState<boolean>(false);
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  return (
    <>
      <NavbarComponent />
      <MobileNavbar openMobileDrawer={openMobileDrawer} setOpenMobileDrawer={setOpenMobileDrawer} />
      <main className={"terms-page"}>
        <Banner
          setImageLoaded={setImageLoaded}
          imageLoaded={imageLoaded} img={BannerImg}
          alt={"Il-Kampanjola"}
          classname={"terms-banner"}
        />

        <section className={"terms-content"}>
          <h1><strong>Terms of Purchase:</strong></h1>
          <h4><strong>Thank you</strong> for choosing <strong>il-Kampanjola</strong> succulent arrangements! I want to bring to your attention some important <strong>terms and conditions</strong> for a smooth experience.</h4>
          <br />

          <ul className={"type"}>
            <li>Pictures shown may vary from actual product due to the live nature of these succulent arrangements.</li>
            <br />
            <li>Please be aware that these succulent arrangements are live plants, and their health and longevity depend on the care provided by you, the customer. I have taken utmost care to provide you with healthy succulents, and it is essential to follow the care instructions provided to ensure their well-being.</li>
            <br />
            <li>Due to the nature of live plants and the potential impact of external care, I enforce a strict "No Returns" policy for my succulent arrangements.</li>
            <br />
            <li>My responsibility ends once the succulent arrangements are collected or delivered to you or a designated recipient. However, I ensure that the arrangements are in excellent condition when handed over.</li>
            <br />
            <li>While I take great care in preparing your succulent arrangements, I want to clarify that I am not liable for any damages caused due to mishandling or negligence on the customer's part. Proper care and attention are crucial for the health of your succulent arrangement.</li><br />
          </ul>
        </section>
        <Footer />
      </main>

      <Helmet>
        <title>Terms & Conditions from Il-Kampanjola</title>
        <meta property="og:title" content="Terms & Conditions from Il-Kampanjola" />
        <meta name="twitter:title" content="Terms & Conditions from Il-Kampanjola" />
        <meta property="og:title" content="Terms & Conditions from Il-Kampanjola" />
        <meta name="description" content="Here you will find the terms and conditions of purchase from Il-Kampanjola Succulent Arrangements." />
        <meta name="keywords" content="terms, terms of purchase, conditions, care, healthy succulents, health, healthy, collection, collections, products, Il-Kampanjola, kampanjola, succulent, succulents, arrangements, pots, plants, flowers, bouquet, gift, gifts, hand-made, hand-crafted, malta, unique, custom made" />
        <meta property="og:image" content="%PUBLIC_URL%/Kampanjola.jpg" />
        <link rel="canonical" href="https://www.kampanjolacreations.com/terms" />
      </Helmet>
    </>
  );
}

export default Terms;
