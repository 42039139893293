import React from "react";
import {Link} from "react-router-dom";
import Button from "rsuite/Button";
import Footer from "../../footer/Footer";
import useScrollToTop from "../../../hooks/useScrollToTop";
import {Helmet} from "react-helmet-async";
import GalleryIcon from "@rsuite/icons/Image";
import TacIcon from "@rsuite/icons/DocPass";
import CareIcon from "@rsuite/icons/PieChart";
import ContactIcon from "@rsuite/icons/Speaker";
import CollectionIcon from "@rsuite/icons/legacy/Creative";
import AboutIcon from "@rsuite/icons/Peoples";
import Logo from "./../../../images/logos/Kampanjola-06.png";

const Landing = () => {
  useScrollToTop();

  return (
    <main className={"landing-page"}>
      <section className={"landing-content"}>
        <img className={"landing-logo"} src={Logo} alt={"Il-Kampanjola"} />
        <div className={"cta"}>
          <Button className={"button"} type={"button"} appearance={"primary"}>
            <Link to={"/about"}><AboutIcon /> About Me</Link>
          </Button>
          <Button className={"button"} type={"button"} appearance={"primary"}>
            <Link to={"/collection"}><CollectionIcon /> Collection</Link>
          </Button>
          <Button className={"button"} type={"button"} appearance={"primary"}>
            <Link to={"/gallery"}><GalleryIcon /> Gallery</Link>
          </Button>
          <Button className={"button"} type={"button"} appearance={"primary"}>
            <Link to={"/care"}><CareIcon /> Care</Link>
          </Button>
          <Button className={"button"} type={"button"} appearance={"primary"}>
            <Link to={"/terms"}><TacIcon /> Terms</Link>
          </Button>
          <Button className={"button"} type={"button"} appearance={"primary"}>
            <Link to={"/contact"}><ContactIcon /> Contact</Link>
          </Button>
        </div>
      </section>
      <Footer />

      <Helmet>
        <title>Il-Kampanjola Succulent Arrangements</title>
        <meta property="og:title" content="Il-Kampanjola Succulent Arrangements" />
        <meta name="twitter:title" content="Il-Kampanjola Succulent Arrangements" />
        <meta property="og:title" content="Il-Kampanjola Succulent Arrangements" />
        <meta name="description" content="Il-Kampanjola Succulent Arrangements" />
        <meta name="keywords" content="Il-Kampanjola, kampanjola, succulent, succulents, arrangements, pots, plants, flowers, bouquet, gift, gifts, hand-made, hand-crafted, malta, unique, custom made, fresh flowers, florist" />
        <link rel="canonical" href="https://www.kampanjolacreations.com/" />
      </Helmet>
    </main>
  )
}

export default Landing;
