import React, {Dispatch} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import { Navbar, Drawer, Nav, NavbarProps } from 'rsuite';
import GalleryIcon from '@rsuite/icons/Image';
import TacIcon from '@rsuite/icons/DocPass';
import CareIcon from "@rsuite/icons/PieChart";
import ContactIcon from "@rsuite/icons/Speaker";
import CollectionIcon from "@rsuite/icons/legacy/Creative";
import AboutIcon from "@rsuite/icons/Peoples"
import Logo from "./../../images/logos/Kampanjola-06.png";
import BurgerIcon from "../../images/icons/burger-menu.svg";

interface NavbarTypes {
  activeKey: string;
  appearance: string;
  props?: any;
  onSelect: any;
}

const CustomNavbar = ({ onSelect, activeKey, appearance, ...props }: NavbarTypes & NavbarProps) => {
  const navigate = useNavigate();

  return (
    <Navbar className={"nav-custom"} appearance={appearance} {...props}>
      <Navbar.Brand href="/" style={{padding: 0}}><img width={79} height={56} src={Logo} alt={"logo"}/></Navbar.Brand>
      <Nav onSelect={onSelect} activeKey={activeKey} className={"main-nav"}>
        <Nav.Item onClick={() => navigate("/about")} className={"item"} eventKey="/about" style={activeKey === "/about" ? {backgroundColor: '#f3bcc4'} : undefined} icon={<AboutIcon />}>About Me</Nav.Item>
        <Nav.Item onClick={() => navigate("/collection")} className={"item"} eventKey="/collection" style={activeKey === "/collection" ? {backgroundColor:  '#f3bcc4'} :  undefined} icon={<CollectionIcon />}>Collection</Nav.Item>
        <Nav.Item onClick={() => navigate("/gallery")} className={"item"} eventKey="/gallery" style={activeKey === "/gallery" ? {backgroundColor:  '#f3bcc4'} :  undefined} icon={<GalleryIcon />}>Gallery</Nav.Item>
        <Nav.Item onClick={() => navigate("/care")} className={"item"} eventKey="/care" style={activeKey === "/care" ? {backgroundColor: '#f3bcc4'} : undefined} icon={<CareIcon />}>Care</Nav.Item>
        <Nav.Item onClick={() => navigate("/terms")} className={"item"} eventKey="/terms" style={activeKey === "/terms" ? {backgroundColor: '#f3bcc4'} : undefined} icon={<TacIcon />}>Terms</Nav.Item>
        <Nav.Item onClick={() => navigate("/contact")} className={"item"} eventKey="/contact" style={activeKey === "/contact" ? {backgroundColor: '#f3bcc4'} : undefined} icon={<ContactIcon />}>Contact</Nav.Item>
      </Nav>
    </Navbar>
  );
};

const NavbarComponent = () => {
  const path = useLocation();
  const [activeKey, setActiveKey] = React.useState(path.pathname);

  return (
    <CustomNavbar appearance="inverse" activeKey={activeKey} onSelect={setActiveKey} />
  );
};

export interface MobileNavbarType {
  openMobileDrawer: boolean;
  setOpenMobileDrawer: Dispatch<boolean>;
}

export const MobileNavbar = ({openMobileDrawer, setOpenMobileDrawer}: MobileNavbarType & NavbarProps) => {
  const location = useLocation();
  const [activeKey, setActiveKey] = React.useState(location.pathname);
  const navigate = useNavigate();

  return (
    <header className={"mobile-nav"}>
      <div className={"icon-holder"}>
        <img src={BurgerIcon} width={20} onClick={() => setOpenMobileDrawer(true)} alt={"burger icon"} />
      </div>

      <Drawer size={"xs"} className={"mobile-nav-drawer"} placement={"left"} open={openMobileDrawer} onClose={() => setOpenMobileDrawer(false)}>
        <Drawer.Header onClick={() => navigate("/")}>Il-Kampanjola</Drawer.Header>
        <Drawer.Body onClick={() => setOpenMobileDrawer(false)}>
          <Nav onSelect={setActiveKey} activeKey={activeKey} className={"main-nav"}>
            <Nav.Item onClick={() => navigate("/about")} className={"item"} eventKey="/about" style={activeKey === "/about" ? {backgroundColor: '#f3bcc4'} : undefined} icon={<AboutIcon />}>About Me</Nav.Item>
            <Nav.Item onClick={() => navigate("/collection")} className={"item"} eventKey="/collection" style={activeKey === "/collection" ? {backgroundColor:  '#f3bcc4'} :  undefined} icon={<CollectionIcon />}>Collection</Nav.Item>
            <Nav.Item onClick={() => navigate("/gallery")} className={"item"} eventKey="/gallery" style={activeKey === "/gallery" ? {backgroundColor:  '#f3bcc4'} :  undefined} icon={<GalleryIcon />}>Gallery</Nav.Item>
            <Nav.Item onClick={() => navigate("/care")} className={"item"} eventKey="/care" style={activeKey === "/care" ? {backgroundColor: '#f3bcc4'} : undefined} icon={<CareIcon />}>Care</Nav.Item>
            <Nav.Item onClick={() => navigate("/terms")} className={"item"} eventKey="/terms" style={activeKey === "/terms" ? {backgroundColor: '#f3bcc4'} : undefined} icon={<TacIcon />}>Terms</Nav.Item>
            <Nav.Item onClick={() => navigate("/contact")} className={"item"} eventKey="/contact" style={activeKey === "/contact" ? {backgroundColor: '#f3bcc4'} : undefined} icon={<ContactIcon />}>Contact</Nav.Item>
          </Nav>
        </Drawer.Body>
      </Drawer>
    </header>
  );
}

export default NavbarComponent;
