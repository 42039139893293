import React, {useState} from "react";
import {Link} from "react-router-dom";
import NavbarComponent, {MobileNavbar} from "../../navbar/NavbarComponent";
import useScrollToTop from "../../../hooks/useScrollToTop";
import Footer from "../../footer/Footer";
import Banner from "../../banner/Banner";
import { Helmet } from 'react-helmet-async';
import BannerImg from "../../../images/banner/banner3.svg";
import Images1 from "../../../images/cups-bowls/29.png"
import Images2 from "../../../images/pots/11.png"
import Images3 from "../../../images/wooden-planters/7.png";
import Images4 from "../../../images/terracotta-pots/15.png";
import Images5 from "../../../images/gift-boxes/1.png";
import Images6 from "../../../images/paintings/19.png";

const Collections = () => {
  const [openMobileDrawer, setOpenMobileDrawer] = useState<boolean>(false);
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  useScrollToTop();

  return (
    <>
      <NavbarComponent />
      <MobileNavbar openMobileDrawer={openMobileDrawer} setOpenMobileDrawer={setOpenMobileDrawer} />
      <Banner
        setImageLoaded={setImageLoaded}
        imageLoaded={imageLoaded}
        img={BannerImg}
        alt={"Il-Kampanjola"}
        classname={"collections-banner"}
      />

      <main className={"collections-page"}>
        <section className={"collections"}>
          <div className={"collection"}>
            <h1>My Collection</h1>

            <div className={"desc-holder"}>
              <p>Gifting someone a succulent arrangement is a wonderful and unique way to show that you care… and if you are falling in love with these succulent creations, don’t hesitate to pamper yourself with one as well!</p>
              {/*<p>Unlike cut flowers, flower arrangements and flower bouquets, which have a limited lifespan and can wilt quickly, these rooted succulents arrangements are long-lasting and require minimal care, making them a thoughtful and practical gift. Succulents also symbolise growth, new beginnings, and resilience, making them a great way to wish someone well on a special occasion, such as a birthday or to welcome a new baby.</p>*/}
              {/*<p>Succulent arrangements with their diverse shapes, sizes, and colours, can be arranged in a variety of ways to create a custom and eye-catching design. Whether it's a small, potted arrangement or a larger, more elaborate one, my handcrafted succulent arrangement are sure to brighten up any space.</p>*/}
              <p>So if you are looking for a gift that is different, handcrafted and unique, take a look at my succulent collection below.  They are the perfect and meaningful way to show someone that you care.
                {/*Do you have something particular in mind and not finding what you want? Do not worry, let's have a chat and I will customise a design just for you.*/}
              </p>
            </div>

            <section className={"collections-holder"}>
              <div className={"cups-bowls"}>
                <Link to={"/collection/cups-bowls"}>
                  <img className={"image"} src={Images1} alt={"succulents-bowl"} />
                  <h4 className={"title"}>Cups</h4>
                </Link>
              </div>

              <div className={"ceramic-pots"}>
                <Link to={"/collection/ceramic-pots"}>
                  <img className={"image"} src={Images2} alt={"succulents-bowl"} />
                  <h4 className={"title"}>Pots</h4>
                </Link>
              </div>

              <div className={"ceramic-pots"}>
                <Link to={"/collection/gift-boxes"}>
                  <img className={"image"} src={Images5} alt={"gift-boxes"} />
                  <h4 className={"title"}>Gift Boxes</h4>
                </Link>
              </div>

              <div className={"ceramic-pots"}>
                <Link to={"/collection/christmas-duo"}>
                  <img className={"image"} src={Images6} alt={"christmas-duo"} />
                  <h4 className={"title"}>Christmas Duo</h4>
                </Link>
              </div>

              <div className={"wooden-planters"}>
                <Link to={"/collection/wooden-planters"}>
                  <img className={"image"} src={Images3} alt={"succulents-bowl"} />
                  <h4 className={"title"}>Wooden Planters</h4>
                </Link>
              </div>

              <div className={"terracotta-pots"}>
                <Link to={"/collection/terracotta-pots"}>
                  <img className={"image"} src={Images4} alt={"succulents-bowl"} />
                  <h4 className={"title"}>Terracotta Pots</h4>
                </Link>
              </div>
            </section>

          </div>
        </section>

        <Footer />
      </main>

      <Helmet>
        <title>My Collections from Il-Kampanjola</title>
        <meta property="og:title" content="My Collections from Il-Kampanjola" />
        <meta name="twitter:title" content="My Collections from Il-Kampanjola" />
        <meta property="og:title" content="My Collections from Il-Kampanjola" />
        <meta name="description" content="Welcome to my collections page with my products on display for sale at Il-Kampanjola Succulent Arrangements" />
        <meta name="keywords" content="collection, collections, products, shop, buy, goods, themes, for sale, Il-Kampanjola, kampanjola, succulent, succulents, arrangements, pots, plants, flowers, bouquet, gift, gifts, hand-made, hand-crafted, malta, unique, custom made" />
        <meta property="og:image" content="%PUBLIC_URL%/Kampanjola.jpg" />
        <link rel="canonical" href="https://www.kampanjolacreations.com/collection" />
      </Helmet>
    </>
  );
}

export default Collections;
