import Loader from "../../images/banner/rectangle.png";
import React, {Dispatch} from "react";

interface BannerInterface {
  setImageLoaded: Dispatch<boolean>;
  imageLoaded: boolean;
  img: any;
  alt: string;
  classname: string;
}

const Banner = ({setImageLoaded, imageLoaded, img, alt, classname}: BannerInterface) => {
  return (
    <img
      onLoad={() => setImageLoaded(true)}
      className={imageLoaded ? classname : undefined}
      src={imageLoaded ? img : Loader}
      alt={alt}
    />
  );
}

export default Banner;
